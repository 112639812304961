import Router from "@index/router";

export default function (path, params = {}) {
  switch (path) {
    case "poll/before":
    case "before": {
      Router.push({
        name: "Sign",
        params,
      });
      break;
    }
    case "poll/final":
    case "final": {
      window.open("/final", "_self");
      break;
    }
    case "poll/card":
    case "card": {
      Router.push({
        name: "Card",
        params: {
          ...params,
          allow: true,
        },
      });
      break;
    }
    default: {
      Router.push({
        name: "ContactInfo",
      });
    }
  }
}
