var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"personal-information"},[_c('div',{staticClass:"personal-information__line"},[_c('Field',{attrs:{"error":_vm.formErrors.lastname,"title":"Фамилия"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('Field',{attrs:{"error":_vm.formErrors.firstname,"title":"Имя"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('div',{staticClass:"personal-information__line"},[_c('Field',{attrs:{"error":_vm.formErrors.patronymic,"title":"Отчество","subtitle":"(если есть)"},model:{value:(_vm.form.patronymic),callback:function ($$v) {_vm.$set(_vm.form, "patronymic", $$v)},expression:"form.patronymic"}}),_c('div',{staticClass:"personal-information__combined"},[_c('Date',{attrs:{"error":_vm.formErrors.birthday,"title":"Дата рождения"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),_c('div',[_c('Radio',{attrs:{"error":_vm.formErrors.gender,"title":"Пол","list":[
            {
              key: '0',
              value: 'Мужской',
            },
            {
              key: '1',
              value: 'Женский',
            } ]},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }