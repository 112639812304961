<template>
  <div class="personal-information">
    <div class="personal-information__line">
      <Field
        v-model="form.lastname"
        :error="formErrors.lastname"
        title="Фамилия"
      />
      <Field
        v-model="form.firstname"
        :error="formErrors.firstname"
        title="Имя"
      />
    </div>
    <div class="personal-information__line">
      <Field
        v-model="form.patronymic"
        :error="formErrors.patronymic"
        title="Отчество"
        subtitle="(если есть)"
      />
      <div class="personal-information__combined">
        <Date
          v-model="form.birthday"
          :error="formErrors.birthday"
          title="Дата рождения"
        />
        <div>
          <Radio
            v-model="form.gender"
            :error="formErrors.gender"
            title="Пол"
            :list="[
              {
                key: '0',
                value: 'Мужской',
              },
              {
                key: '1',
                value: 'Женский',
              },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./personal-information.scss";

import Field from "@index/components/common/field/Field";
import Date from "@index/components/common/field/Date";
import Radio from "@index/components/common/radio/Radio";

import validation from "@index/mixins/validation";
import Validation from "@index/ext/validation/Validation";

import {
  MIN as MIN_VAL,
  MAX as MAX_VAL,
  NAME_EXP,
} from "@index/const/validationValues";
const NAME_RULES = [
  Validation.NO_FILTHY,
  [Validation.EXP, NAME_EXP],
  [Validation.MIN, MIN_VAL],
  [Validation.MAX, MAX_VAL],
];
const NAME_ERROR_MSG = {
  [Validation.EXP]: "Допустимы только русские буквы, дефис и апостроф",
};

const MAX_BORROWER_OLD = 90;
const MIN_BORROWER_OLD = 18;

import { year, month, day } from "@index/helpers/date/currentDate";
const MAX_BORROWER_YEAR = `${day}.${month}.${year - MAX_BORROWER_OLD}`;
const MIN_BORROWER_YEAR = `${day}.${month}.${year - MIN_BORROWER_OLD}`;

import { mapGetters } from "vuex";

export default {
  name: "PersonalInformation",
  mixins: [validation],
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        patronymic: "",
        birthday: "",
        gender: "",
      },
      formRules: {
        firstname: [Validation.REQUIRED, ...NAME_RULES],
        lastname: [Validation.REQUIRED, ...NAME_RULES],
        patronymic: NAME_RULES,
        birthday: [
          Validation.REQUIRED,
          Validation.DATE,
          [Validation.DATE_LESS, MIN_BORROWER_YEAR],
          [Validation.DATE_MORE, MAX_BORROWER_YEAR],
        ],
        gender: [Validation.REQUIRED],
      },
      customErrors: {
        firstname: NAME_ERROR_MSG,
        lastname: NAME_ERROR_MSG,
        patronymic: NAME_ERROR_MSG,
        birthday: {
          [Validation.DATE_LESS]: `Мы не выдаем займ лицам не достигшим ${MIN_BORROWER_OLD} лет`,
          [Validation.DATE_MORE]: `Возраст заемщика не должен превышать ${MAX_BORROWER_OLD} лет`,
        },
      },
    };
  },
  created() {
    this.form.firstname = this.user.contactData.firstname;
    this.form.lastname = this.user.contactData.lastname;
    this.form.patronymic = this.user.contactData.patronymic;
    this.form.birthday = this.user.contactData.birthday;
    this.form.gender = String(this.user.contactData.gender);
  },
  computed: {
    ...mapGetters({
      user: "application/user",
    }),
  },
  watch: {
    "form.birthday"(v) {
      this.$emit("changeBirthday", v);
    },
  },
  components: {
    Field,
    Date,
    Radio,
  },
};
</script>
