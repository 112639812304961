<template>
  <div class="lite">
    <Header class="lite__header" />
    <form class="lite__content" @submit.prevent="validate" novalidate>
      <Calculator v-model="calculator" :showRangeInfo="3" class="lite__calc" />
      <Title class="lite__title"> Контактная информация </Title>
      <ContactInformation
        ref="contactInformation"
        class="lite__contact-information"
      />
      <PersonalInformation
        ref="personalInformation"
        class="lite__personal-information"
      />
      <Title class="lite__title"> Паспортные данные </Title>
      <PassportInformation ref="passportInformation" />
      <div class="lite__agreement">
        <Checkbox :error="isSubmit && !agreement" v-model="agreement">
          <div v-html="acceptanceText"></div>
        </Checkbox>
        <Checkbox
          v-if="autoPaymentText"
          :error="isSubmit && !autoPayment"
          v-model="autoPayment"
        >
          <div v-html="autoPaymentText"></div>
        </Checkbox>
      </div>
      <Title class="lite__title">
        Привяжите свою банковскую карту<br />
        для получения решения и активации заявки
      </Title>
      <Highlight class="lite__card-hilight">
        Требования к карте: не нулевой баланс, наличие транзакций за последнии
        30 дней.
      </Highlight>
      <div class="lite__card-description" v-html="cardDescription"></div>
      <Card ref="card" />
      <div class="lite__actions">
        <Button type="submit"> Продолжить </Button>
      </div>
    </form>
    <Footer />
  </div>
</template>

<script>
import Header from "@index/components/main/header/Header";
import ContactInformation from "@index/components/application/contact-information/ContactInformation";
import PersonalInformation from "@index/components/application/personal-information/PersonalInformation";
import PassportInformation from "@index/components/application/passport-information/PassportInformation";
import Checkbox from "@index/components/common/checkbox/Checkbox";
import Button from "@index/components/gui/button/Button";
import Title from "@index/components/gui/title/Title";
import Card from "@index/components/application/card/Card";
import openRoute from "@index/helpers/router/openRoute";

import checkCard from "@index/api/checkCard";

import setMask from "@index/helpers/string/setMask";
import sbg from "@index/mixins/sbg";

import "./lite.scss";

export default {
  mixins: [sbg],
  data() {
    return {
      calculator: 10000,
      agreement: false,
      autoPayment: false,
      isSubmit: false,
    };
  },
  created() {
    this.fieldFocusEvent = this.fieldFocusEvent.bind(this);
    this.autoPayment = this.agreement = this.isSbg;
  },
  mounted() {
    if (!this.isSbg) return;

    setTimeout(() => {
      const href = location.href;

      history.pushState(null, document.title, "/final");
      history.pushState(null, document.title, href);

      document.querySelectorAll("input").forEach((field) => {
        field.addEventListener("input", this.fieldFocusEvent);
      });
    });
  },
  computed: {
    acceptanceText() {
      return this.$DICTIONARY.acceptanceText;
    },
    autoPaymentText() {
      return this.$DICTIONARY.acceptanceAutoPayment;
    },
    cardDescription() {
      return this.$DICTIONARY.comsa;
    },
  },
  methods: {
    fieldFocusEvent() {
      this.$store.commit("application/showFinal", false);

      document.querySelectorAll("input").forEach((field) => {
        field.removeEventListener("focus", this.fieldFocusEvent);
      });
    },
    validate() {
      this.isSubmit = true;
      this.$refs.card.validate();
      this.$refs.passportInformation.validate();
      this.$refs.personalInformation.validate();
      this.$refs.contactInformation.validate();

      ((this.autoPaymentText && this.autoPayment) || !this.autoPaymentText) &&
        this.$refs.card.isValid &&
        this.$refs.passportInformation.isValid &&
        this.$refs.personalInformation.isValid &&
        this.$refs.contactInformation.isValid &&
        this.agreement &&
        this.submit();
    },
    async submit() {
      try {
        await checkCard(this.$refs.card.form.number);
      } catch (e) {
        this.$refs.card.error = "Невалидная карта";

        return;
      }

      const data = {
        contactData: {
          ...this.$refs.passportInformation.form.contactData,
          ...this.$refs.contactInformation.form,
          ...this.$refs.personalInformation.form,
        },
        passportData: {
          ...this.$refs.passportInformation.form.passportData,
          passportissuecode: setMask(
            this.$refs.passportInformation.form.passportData.passportissuecode,
            "###-###"
          ),
        },
      };

      console.log(data);

      // @TODO валидация с бэка
      const { status, next_step } = await this.$store.dispatch(
        "application/lite",
        data
      );

      const params = {
        isLite: true,
        cardInfo: this.$refs.card.form,
      };

      if (status === "sendSMS") {
        this.$router.push({
          name: "Sign",
          params,
        });
      } else if (next_step) {
        openRoute(next_step, params);
      } else {
        // @TODO обсудить этот момент
        this.$store.commit("error/showError");
      }
    },
  },
  components: {
    Header,
    ContactInformation,
    PersonalInformation,
    PassportInformation,
    Checkbox,
    Button,
    Title,
    Card,
    Calculator: () => import("@index/components/common/calculator/Calculator"),
    Highlight: () => import("@index/components/gui/highlight/Highlight"),
    Footer: () => import("@index/components/main/footer/Footer"),
  },
};
</script>
